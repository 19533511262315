import autoBind from 'auto-bind';
import { validatorMeasureHeight } from './utils';

export class EValidatorEvents {
  constructor(component) {
    autoBind(this);

    this.component = component;
    this.state = component.state;
    this.refs = component.refs;
  }

  onHeaderClick() {
    if (!this.refs.contentElement || this.refs.contentElement._isTransitionRunning) { return; }

    this.refs.contentElement._isTransitionRunning = true;
    this.state.isContentVisible = !this.state.isContentVisible;

    this.refs.contentElement.addEventListener('transitionend', this.onTransitionEnd);

    const contentHeight = validatorMeasureHeight(this.refs.contentElement);
    this.refs.contentElement.style.height = this.state.isContentVisible ? `${contentHeight}px` : 0;
    this.refs.contentElement[this.state.isContentVisible ? 'setAttribute' : 'removeAttribute']('opened', '');
    this.component.dispatchEvent(new CustomEvent('trigger', {
      detail: {
        opened: this.state.isContentVisible
      }
    }));
  };

  onActionClick(event) {
    event.stopPropagation();
    if (this.state.actionHref) { return; }

    event.preventDefault();
    this.component.dispatchEvent(new CustomEvent('action'));
  }

  onTransitionEnd() {
    this.refs.contentElement.removeEventListener('transitionend', this.onTransitionEnd);
    this.refs.contentElement._isTransitionRunning = false;
    this.refs.contentElement.style.height = this.state.isContentVisible ? 'auto' : 0;
  };
}
