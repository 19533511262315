import { convertAttributeToBoolean, convertAttributeToString, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { EValidatorTemplate } from './template';
import { EValidatorState } from './state';
import { EValidatorEvents } from './events';

class EValidator extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();

    this.refs = {};
    this.state = new EValidatorState(this);
    this.events = new EValidatorEvents(this);
    this.template = new EValidatorTemplate(this);

    this.refs.wrapper = this.template.createWrapper();
    this.refs.contentElement = null;

    this.addEventListener('headerClick', this.events.onHeaderClick);
    this.addEventListener('actionClick', this.events.onActionClick);
  }

  connectedCallback() {
    this.childrenChangedCallback([].map.call(this.children, child => [child]));

    this.requestRender().then(() => {
      this.insertAdjacentElement('afterbegin', this.refs.wrapper);
    });
  }

  childrenChangedCallback() {
    this.refs.contentElement = this.querySelector('e-validator-content');

    this._setVisibilityOfContentElement();

    this.requestRender();
  }

  static get observedAttributes() {
    return [
      'header',
      'subject',
      'subheader',
      'status',
      'action',
      'action-href',
      'action-type',
      'summary',
      'summary-value',
      'summary-type',
      'opened',
      'standalone'
    ];
  }

  set header(value) {
    this.state.header = value;
  }

  set subject(value) {
    this.state.subject = value;
  }

  set subheader(value) {
    this.state.subheader = convertAttributeToString(value);
  }

  set action(value) {
    this.state.action = convertAttributeToString(value);
  }

  set actionHref(value) {
    this.state.actionHref = convertAttributeToString(value);
  }

  set actionType(value) {
    this.state.actionType = convertAttributeToString(value);
  }

  set status(value) {
    const stringValue = convertAttributeToString(value);

    this.state.status = stringValue;
    this.state.iconColor = stringValue;
    this.state.iconName = stringValue;
  }

  set summary(value) {
    this.state.summary = convertAttributeToString(value);
  }

  set summaryValue(value) {
    this.state.summaryValue = convertAttributeToString(value);
  }

  set summaryType(value) {
    this.state.summaryColor = convertAttributeToString(value);
    this.state.summaryType = convertAttributeToString(value);
  }

  set opened(value) {
    const isContentVisible = convertAttributeToBoolean(value);
    this.state.isContentVisible = isContentVisible;

    this._setVisibilityOfContentElement();
  }

  set standalone(value) {
    if (value !== this.getAttribute('standalone')) {
      if (value) {
        this.setAttribute('standalone', '');
      } else {
        this.removeAttribute('standalone');
      }
    }
  }

  _render() {
    render(this.refs.wrapper, this.template.createElement());
  }

  _setVisibilityOfContentElement() {
    if (!this.refs.contentElement) { return; }

    this.refs.contentElement.style.height = this.state.isContentVisible ? 'auto' : 0;
    this.refs.contentElement[this.state.isContentVisible ? 'setAttribute' : 'removeAttribute']('opened', '');
  }
}

export default EValidator;
