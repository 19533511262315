import { EDSComponent } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { ECardState } from './state';
import { ECardEvents } from './events';
import { ECardTemplate } from './template';
export class ECard extends EDSComponent {
  static componentName = 'card';

  init() {
    this.state = new ECardState(this);
    this.events = new ECardEvents(this);
    this.template = new ECardTemplate(this);

    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.requestRender();
  }

  render() {
    render(this.shadowRoot, this.template.createElement());
  }
}
