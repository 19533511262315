import { debounce } from '@emartech/ui-framework-utils/dom';
import flipper from '../../../../utils/flipper/index.js';

class EDatagridServerState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._server = '';
    this._customServer = null;
    this._requestCount = 0;
    this._serverErrorState = false;
    this._isLoading = false;
    this._switchLoadingItems = [];
  }

  get isServerSide() {
    return !!(this._server || this._customServer);
  }

  get server() {
    return this._server;
  }

  set server(value) {
    if (this._server === value) { return; }
    this._server = value;
    this.requestGetDataFromServer();
    this._coreState.bulkState.logBulkWithServer();
    this.requestRender();
  }

  get customServer() {
    return this._customServer;
  }

  set customServer(value) {
    if (this._customServer === value) { return; }
    this._customServer = value;
    this.requestGetDataFromServer();
    this._coreState.bulkState.logBulkWithServer();
    this.requestRender();
  }

  get serverErrorState() {
    return this._serverErrorState;
  }

  set serverErrorState(value) {
    this._serverErrorState = value;
    this.requestRender();
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
    this.requestRender();
  }

  get switchLoadingItems() {
    return this._switchLoadingItems;
  }

  set switchLoadingItems(value) {
    this._switchLoadingItems = value;
    this.requestRender();
  }

  get requestCount() {
    return this._requestCount;
  }

  set requestCount(value) {
    this._requestCount = value;
    this.requestRender();
  }

  get requestGetDataFromServer() {
    if (flipper.isOn('ui_datagrid_debounce_server')) {
      return debounce(this._getDataFromServer, this);
    }

    return this._getDataFromServer;
  }

  addToSwitchLoading(rowData, columnContentKey) {
    this._switchLoadingItems = this._switchLoadingItems.concat({ data: rowData, columnContentKey });
    this.requestRender();
  }

  removeFromSwitchLoading(contentKey) {
    this._switchLoadingItems = this._switchLoadingItems
      .filter(loadingItem => loadingItem.columnContentKey !== contentKey);
    this.requestRender();
  }

  _getDataFromServer() {
    if (!this.isServerSide) { return; }

    this._isLoading = true;

    const payload = {
      requestCount: ++this._requestCount,
      pageSize: this._coreState.paginationState.pageSize,
      offset: (this._coreState.paginationState.pageNumber - 1) * this._coreState.paginationState.pageSize,
      sortingKey: this._coreState.filterState.sorting.key,
      sortingOrder: this._coreState.filterState.sorting.order,
      filterValues: this._coreState.filterState.filterValues,
      search: this._coreState.filterState.searchKeyword.keyword
    };

    const request = this._customServer ? this._customServer(payload) : this._fetchFromServer(payload);
    request
      .then(data => {
        if (this._requestCount > data.requestCount) { return; }

        this._coreState.content = data.content;
        this._coreState.paginationState.itemCount = data.itemCount;
        this._coreState.filterState.filteredItemCount = data.filteredItemCount;
        this._serverErrorState = false;
        this._isLoading = false;
        this._coreState.bulkState.updateSelection();
      }).catch(() => {
        this._serverErrorState = true;
        this._isLoading = false;
      }).finally(() => {
        this._requestCallback();
      });
  }

  _fetchFromServer(payload) {
    return fetch(this.server, {
      method: 'post',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(payload),
      credentials: 'same-origin'
    })
      .then(response => {
        if (!response.ok) { throw Error(response); }
        return response.json();
      })
      .then(response => response.data);
  }

  _requestCallback() {
    if (!this._coreState.idKey) {
      this._coreState.bulkState.deselectAll();
    }

    this.requestRender();
  }
}

export default coreState => new EDatagridServerState(coreState);
