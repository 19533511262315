import autoBind from 'auto-bind';

export class ECardEvents {
  constructor(component) {
    autoBind(this);

    this.state = component.state;
  }

  onPreviewChange(event) {
    const slot = event.target;
    const hasPreview = slot.assignedNodes().length !== 0;
    this.state.hasPreview = hasPreview;
  }

}
