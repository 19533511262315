import { html } from 'uhtml';

export class ECardTemplate {

  constructor(component) {
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
        ${this.createHeaderStartSlot()}
        ${this.createHeaderEndSlot()}
        ${this.createPreviewEndSlot()}
        ${this.createContentSlot()}
        ${this.createActionsSlot()}
      `;
  }

  createContentSlot() {
    return html`
      <slot part='content' name='content'></slot>
    `;
  }

  createActionsSlot() {
    return html`
      <slot part='actions' name='actions'></slot>
    `;
  }

  createHeaderStartSlot() {
    return html`
      <slot part='header-start' name='header-start'></slot>
    `;
  }

  createHeaderEndSlot() {
    return html`
      <slot part='header-end' name='header-end'></slot>
    `;
  }

  createPreviewEndSlot() {
    const part = this.state.hasPreview ? 'preview' : null;

    return html`
      <slot
        part=${part}
        name='preview'
        @slotChange=${this.events.onPreviewChange}
      ></slot>
    `;
  }
};
