import { render, html } from 'uhtml';
import classNames from 'clsx';

const renderPageNumber = (state, label, clickHandler) => {
  const isCurrentPage = state.currentPage === parseInt(label);
  const isDisabled = label === state.hiddenPagesPlaceholder;

  const buttonClassList = classNames('e-btn', 'e-btn-onlyicon', 'e-btn-borderless', {
    'e-btn-disabled': isDisabled,
    'e-btn-active': isCurrentPage
  });

  const className = classNames('e-pagination__item', {
    'e-pagination__item-disabled': isDisabled,
    'e-pagination__item-active': isCurrentPage
  }, buttonClassList);

  const defaultFunction = event => event.preventDefault();
  const clickEvent = isCurrentPage || isDisabled ? defaultFunction : clickHandler.bind(null, label, 'page');

  return html`
    <button type="button" ?disabled="${isDisabled}" class="${className}" onclick="${clickEvent}">${label}</button>
  `;
};

const generatePageGroup = (state, clickHandler) => {
  const labels = state.generateLabels();
  return labels.map(label => renderPageNumber(state, label, clickHandler));
};

const generateLoadingState = () => {
  const buttonClassList = 'e-btn e-btn-onlyicon e-btn-borderless e-btn-disabled';
  const skeletonClassNames = classNames('e-pagination__item', buttonClassList);

  const skeletonTextElements = html`
    <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
    <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
    <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
    <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
    <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
  `;

  return skeletonTextElements;
};

export default (element, state, clickHandler) => {
  const isPrevDisabled = state.currentPage === 1 || state.loading;
  const prevButtonClassList = classNames('e-btn', 'e-btn-onlyicon', 'e-btn-borderless', {
    'e-btn-disabled': isPrevDisabled
  });
  const prevClassName = classNames('e-pagination__item', 'e-pagination__item-prev', {
    'e-pagination__item-disabled': isPrevDisabled
  }, prevButtonClassList);

  const isNextDisabled = state.currentPage === state.maxPage || state.loading;
  const nextButtonClassList = classNames('e-btn', 'e-btn-onlyicon', 'e-btn-borderless', {
    'e-btn-disabled': isNextDisabled
  });
  const nextClassName = classNames('e-pagination__item', 'e-pagination__item-next', {
    'e-pagination__item-disabled': isNextDisabled
  }, nextButtonClassList);

  const defaultFunction = event => event.preventDefault();
  const prevEvent = isPrevDisabled ? defaultFunction : clickHandler.bind(null, state.currentPage - 1, 'prev');
  const nextEvent = isNextDisabled ? defaultFunction : clickHandler.bind(null, state.currentPage + 1, 'next');

  const iconLeft = 'e-move-left';
  const iconRight = 'e-move-right';

  return render(element, html`
    <div class="${state.loading ? 'e-pagination e-pagination-loading' : 'e-pagination'}">
      <button type="button" ?disabled="${isPrevDisabled}" class="${prevClassName}" onclick="${prevEvent}">
        <e-icon icon=${iconLeft}></e-icon>
      </button>
      ${state.loading ? generateLoadingState() : generatePageGroup(state, clickHandler)}
      <button type="button" ?disabled="${isNextDisabled}" class="${nextClassName}" onclick="${nextEvent}">
        <e-icon icon=${iconRight}></e-icon>
      </button>
    </div>
  `);
};
