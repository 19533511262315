export class ECardState {
  #hasPreview = false;

  constructor(component) {
    this.requestRender = component.requestRender.bind(component);
  }

  get hasPreview() {
    return this.#hasPreview;
  }

  set hasPreview(value) {
    this.#hasPreview = value;
    this.requestRender();
  }
};
