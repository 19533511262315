import { HTMLCustomElement } from '@emartech/ui-framework-utils';

export class EDatagridErrorState extends HTMLCustomElement {
  connectedCallback() {
    this.dispatchEvent(new CustomEvent('error-state.update', { bubbles: true }));
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('error-state.delete'));
  }
}

export default EDatagridErrorState;
