import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import State from './state';
import template from './pagination';

class EPagination extends HTMLCustomElement {
  init() {
    this._state = new State();
    this._events = {};
    this._events.clickHandler = this._clickHandler.bind(this);
  }

  connectedCallback() {
    this._render();
  }

  static get observedAttributes() {
    return ['max-page', 'current-page', 'loading'];
  }

  set maxPage(value) {
    this._state.maxPage = parseInt(value);
    this._render();
  }

  set currentPage(value) {
    this._state.currentPage = parseInt(value);
    this._render();
  }

  set loading(value) {
    this._state.loading = this._convertAttributeToBoolean(value);
    this._render();
  }

  get loading() {
    return this._state.loading;
  }

  get maxPage() {
    return this._state.maxPage;
  }

  _render() {
    template(this, this._state, this._events.clickHandler);
  }

  _clickHandler(page, trigger, event) {
    event.preventDefault();

    this.currentPage = page;

    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      detail: {
        page: this._state.currentPage,
        trigger
      }
    }));
  }
}

export default EPagination;
