import { HTMLCustomElement } from '@emartech/ui-framework-utils';

export class EDatagridEmptyState extends HTMLCustomElement {
  connectedCallback() {
    this.dispatchEvent(new CustomEvent('empty-state.update', { bubbles: true }));
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('empty-state.delete'));
  }
}

export default EDatagridEmptyState;
